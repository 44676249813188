<template>
    <div>
        <b-card no-body>
            <b-card-header>
                فلترة حسب
                <b-button variant="info" @click="localeAddToWhatsApp()"
                    >إضافة إلى WhatsApp</b-button
                >
            </b-card-header>
            <b-card-body class="pb-0">
                <b-row>
                    <b-col cols="12" md="6">
                        <EKInputPickerRange
                            v-model="date"
                            placeholder="اختر مجال زمني"
                            name="filterAddress"
                            label="فلترة حسب تاريخ الإرسال"
                        />
                    </b-col>
                    <b-col cols="12" md="6">
                        <EKSelect
                            clearable
                            label="فلترة حسب المدينة"
                            class="ek-input"
                            name="اسم المدينة"
                            v-model="filterDto.keywordAddress"
                            :options="areas"
                        ></EKSelect>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <EKTable
            :items="filteredUsers"
            :columns="columns"
            @delete-selected="fireDeleteEvent"
            @selected-rows="selected = $event"
        >
            <template slot="items.createdDate" slot-scope="{ value }">
                {{ new Date(value).toLocaleString("en-Gb") }}
            </template>
            <template slot="items.added" slot-scope="{ value }">
                <unicon v-if="value" name="whatsapp" fill="var(--success)" />
                <unicon v-else name="whatsapp" fill="var(--danger)" />
            </template>
            <template slot="items.delete" slot-scope="{ props }">
                <b-button
                    class="btn-icon"
                    variant="flat-secondary"
                    @click="localeDeleteUser(props)"
                >
                    <unicon name="trash-alt" width="22" height="22" />
                </b-button>
            </template>
        </EKTable>
    </div>
</template>

<script>
import EKSelect from "@/EK-core/components/EK-forms/EK-input-select";
import EKTable from "@/EK-core/components/EK-table";
import EKInputPickerRange from "@/EK-core/components/EK-forms/EK-input-picker-range";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
    computed: {
        ...mapState({
            areas: state => state.store.areas,
            usersList: state => state.store.usersList,
            filterDto: state => state.store.filterDto
        }),
        ...mapGetters(["filteredUsers"])
    },
    data: () => ({
        selected: [],
        columns: [
            {
                label: "اسم الزبون",
                field: "name",
                sortable: true
            },
            {
                label: "رقم الهاتف",
                field: "phoneNumber",
                sortable: true
            },
            {
                label: "العنوان",
                field: "area",
                sortable: true
            },
            {
                label: "التاريخ",
                field: "createdDate",
                sortable: false
            },
            {
                label: "Whats Up",
                field: "added",
                sortable: false
            },
            {
                label: "حذف",
                field: "delete",
                sortable: false
            }
        ],
        date:
            new Date(1970) +
            " to " +
            new Date(new Date().getTime() + 24 * 60 * 60 * 1)
    }),
    components: {
        EKTable,
        EKInputPickerRange,
        EKSelect
    },
    created() {
        const date = this.date.split(" to ");
        this.getUser({ start: new Date(date[0]), end: new Date(date[1]) });
        this.$store.dispatch("getAreas");
    },
    methods: {
        ...mapActions([
            "getUser",
            "deleteUser",
            "deleteUsers",
            "addToWhatsApp"
        ]),
        fireDeleteEvent(l) {
            this.deleteUsers(l);
        },
        localeDeleteUser(props) {
            this.deleteUser(props.row.id);
        },
        localeAddToWhatsApp() {
            this.addToWhatsApp(this.selected.map(obj => obj.id));
        }
    },
    watch: {
        date(v) {
            const date = v.split(" to ");
            if (date[0] && date[1]) {
                this.getUser({
                    start: new Date(date[0]),
                    end: new Date(date[1])
                });
            }
        }
    }
};
</script>
<style lang="scss">
.ek-input {
    label {
        padding: 0 !important;
        font-size: 0.857rem !important;
        margin-bottom: 0.2857rem !important;
    }
}
</style>
